import React from 'react'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { SublineStyles } from '../styles/SublineStyles'
import { HeaderStyles } from '../styles/HeaderStyles'

export default function PrivacyPolicy() {
    return (
        <Layout>
            <SEO title={"Privacy Policy"} />
            <HeaderStyles>
                Privacy<br/> Policy
            </HeaderStyles>
            <SublineStyles>
            <span></span>
                <p>No data is collected from the apps we build. If that changes we'll update our policy.</p>
            </SublineStyles>
        </Layout>

    )
}
