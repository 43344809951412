import styled from "styled-components";

export const HeaderStyles = styled.h1`
    margin-top: .1em;
    font-weight: 400;
    font-size: 24rem;
    line-height: .72em;
    transition: font-size 1s;
    text-transform: uppercase;

    @media (max-width: 1440px) {
      font-size: 20rem;
    }  
    @media (max-width: 1024px) {
      margin-top: 30vh;
      font-size: 18rem;
    }  
    @media (max-width: 900px) {
      font-size: 10rem;
    }
    @media (max-width: 768px) {
      margin-top: 42vh;
      font-size: 15.2rem;
    } 
    @media (max-width: 540px) {
      margin-top: 40vh;
      font-size: 8rem;
    }
    @media (max-width: 375px) {
      margin-top: 40vh;
      font-size: 7rem;
    }  
    @media (max-width: 320px) {
      margin-top: 40vh;
      font-size: 6rem;
    }
    @media (max-width: 280px) {
      margin-top: 49vh;
      font-size: 5rem;
    }   
`;