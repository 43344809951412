import styled from "styled-components";

export const SublineStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3em;
  p {
    font-size: 1em;
    text-transform: uppercase;
  }
  a {
    text-decoration: underline;
  }
  @media (max-width: 540px) {
    grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 375px) {
      grid-template-columns: 1fr 1fr;
    }  

  @media (max-width: 320px) {
    grid-template-columns: 1fr 1fr ;
 ;
  }
`